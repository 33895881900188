@import url(./fonts/fonts.css);

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'IBM Plex Sans', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  --color-background: #141414;
  --color-shadow: rgba(255, 255, 255, 0.05);
  --color-hover: #1C1C21;
  --color-primary: #7247EB;
  --color-text: #FAFAFA;
  --color-title: #EBCF47;
  --color-conrtol: #26262C;

  --container-padding: 20px;
  --shadow: inset 0px -1px 0px var(--color-shadow);

  background-color: var(--color-background);
}

ul {
  list-style: none;
}

h2, h3, h4, h5, h6, p {
  color: var(--color-text);
}

h1 {
  color: var(--color-text);
  font-style: normal;
  font-weight: bold;
  font-size: 24px;
  line-height: 36px;
}

h2 {
  font-style: normal;
  font-weight: bold;
  font-size: 40px;
  line-height: 48px;
}

h3 {
  font-style: normal;
  font-weight: bold;
  font-size: 32px;
  line-height: 32px;
}

p {
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 20px;
}
