.info > span {
  font-family: 'IBM Plex Sans', serif;
  font-weight: 500;
  display: block;
  color: var(--color-text);
}

.info > span:last-child {
  color: rgba(250, 250, 250, .6);
}

.info {
  display: flex;
  color: rgba(250, 250, 250, 0.6);
  justify-content: space-between;
  margin-bottom: 8px;
}

.chat {
  color: white;
  display: flex;
  align-items: center;
  padding: var(--container-padding);
  width: 100%;
  gap: 12px;
  text-decoration: none;
}

.lastMessage {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}

.messageLimited {
  max-width: 175px;
}

.lastMessage > span:last-child {
  color: rgba(250, 250, 250, .6);
}

.chatContent {
  width: 100%;
}

.activeChat {
  background-color: #292929;
}
